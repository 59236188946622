import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // New state for loading
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading state

    const response = await fetch('https://www.chaipongmap.com/login.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    });

    const data = await response.json();
    setIsLoading(false); // Reset loading state

    if (data.success) {
      // Login successful, navigate to the Map page
      navigate('/Rendermap'); // Changed to lowercase path
    } else {
      // Show error message from backend
      setErrorMessage(data.message);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center w-full dark:bg-gray-950">
    <div className="bg-white dark:bg-gray-900 shadow-md rounded-lg px-8 py-6 max-w-md">
      <h1 className="text-2xl font-bold text-center mb-4 dark:text-gray-200">Login</h1>
      <form onSubmit={handleLogin}> {/* Make sure to handle form submission */}
        <div className="mb-4">
          <label htmlFor="username" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Username</label>
          <input 
            onChange={(e) => setUsername(e.target.value)} 
            value={username} 
            type="text" 
            id="username" 
            className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" 
            placeholder="Username" 
            required 
          />
        </div>
        <div className="mb-4">
          <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Password</label>
          <input 
            onChange={(e) => setPassword(e.target.value)} 
            value={password} 
            type="password" 
            id="password" 
            className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" 
            placeholder="Enter your password" 
            required 
          />
        </div>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        <button 
          type="submit" 
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" 
          disabled={isLoading}
        >
          {isLoading ? 'Logging in...' : 'Login'}
          
        </button>
      </form>
    </div>
  </div>
  );
};

export default Login;




