import React from 'react';
import { useNavigate } from 'react-router-dom';

const ButtonEditdata = () => {
    const navigate = useNavigate();  // Use navigate hook for route navigation

    return (
        <button
            type="button"
            onClick={() => navigate("/userlist")}  // Navigate to UserList page
            className="overlay-button text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        >
            Edit data
        </button>
    );
};

export default ButtonEditdata;
