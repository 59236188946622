// src/activitiesService.js

export const fetchActivities = async () => {
    try {
        const response = await fetch("https://www.chaipongmap.com/activities_markers.php");
        if (!response.ok) {
            throw new Error('Failed to fetch Activities');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching activities:', error);
        throw error;
    }
};

export const readActivities = async () => {
    try {
        const response = await fetch("https://www.chaipongmap.com/read_activities.php");
        if (!response.ok) {
            throw new Error('Failed to fetch Activities');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching activities:', error);
        throw error;
    }
};

export const createActivities = async (newActivities) => {
    try {
        const response = await fetch('https://www.chaipongmap.com/create_activities.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newActivities),
        });
        if (!response.ok) {
            throw new Error('Failed to create Activities');
        }
        return await response.json();
    } catch (error) {
        console.error('Error creating Activities:', error);
        throw error;
    }
};

export const updateActivities = async (editableActivities) => {
    // Validate required properties
    const { id, location_id, activity_date, activity } = editableActivities;
    if (!id || !location_id || !activity_date || !activity) {
        throw new Error('All fields are required to update activities.');
    }

    const endpoint = 'https://www.chaipongmap.com/update_activities.php'; // Configurable endpoint

    try {
        const response = await fetch(endpoint, {
            method: 'PUT', // Ensure you're using PUT method
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id,
                location_id,
                activity_date,
                activity,
            }),
        });

        // Check if the response is okay
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Failed to update activity: ${errorData.error || 'Unknown error'}`);
        }

        const data = await response.json();

        // Check the response message
        if (data.message) {
            return data; // Return the message from the response
        } else {
            throw new Error('Unexpected response format');
        }
    } catch (error) {
        console.error('Error updating activity:', error);
        throw error; // Throw error to be handled by the calling function
    }
};


export const deleteActivities = async (id) => {
    try {
        console.log("Deleting Activities with ID:", id); // Debugging line
        const response = await fetch('https://www.chaipongmap.com/delete_activities.php', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ID: id }) // Use "ID" instead of "id"
        });

        const data = await response.json();

        console.log("Response from delete Activities:", data); // Debugging line

        if (data.status === 'error') {
            console.error('Error deleting Activities:', data.message);
            throw new Error(data.message);
        }

        return data;
    } catch (error) {
        console.error('Error deleting Activities:', error);
        throw error;
    }
};
