import React from 'react';
import { MapContainer, TileLayer, LayersControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import MapMarkers from './databaserender';

const { BaseLayer } = LayersControl;

const MapComponent = ({showMarker1}) => {
    return (
        <MapContainer 
            style={{ height: "100%", width: "100%", zIndex: "30" }} // Adjusted height and width
            center={[13, 100]}
            zoom={6}
            zoomControl={false}
        >
            <LayersControl position="topright">
                {/* Base Layers */}
                <BaseLayer name="OpenStreetMap" checked>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                </BaseLayer>
                <BaseLayer name="Alidade Satellite">
                    <TileLayer
                        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                        attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                    />
                </BaseLayer>
                {showMarker1 && <MapMarkers />}
            </LayersControl>
        </MapContainer>
    );
}

export default MapComponent;
