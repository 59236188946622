import React, { useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { PiMapPinAreaFill } from "react-icons/pi";
import Checkbox from './Checkbox'; // Ensure Checkbox component is correctly imported
import { AiTwotoneLeftCircle } from "react-icons/ai";
import { BiLogOut } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';

const Sidebar = ({ showMarker1, toggleMarker1 }) => {
  const [open, setOpen] = useState(true);
  const [subMenuOpen, setSubMenuOpen] = useState(null);
  const navigate = useNavigate(); // Initialize the navigate function

  const toggleSubMenu = (index) => {
    if (!open) {
      setOpen(true);
    } else {
      setSubMenuOpen(subMenuOpen === index ? null : index);
    }
  };

  const Menus = [
    {
      title: 'ข้อมูลจุด',
      icon: <PiMapPinAreaFill />,
      subMenus: [
        { title: 'ตำแหน่งงานเทศกาล', type: 'checkbox', checked: showMarker1, toggle: toggleMarker1 },
      ],
    },
    {
      title: 'ออกจากระบบ',
      icon: <BiLogOut />,
      onClick: () => navigate('/', { replace: true }), // Navigate to login page without adding to history
    },
  ];

  return (
    <div className="flex z-40">
      <div
        className={`${open ? 'w-60' : 'w-5'} bg-dark-purple h-screen p-5 pt-8 relative duration-500`}
        onClick={() => !open && setOpen(true)}
      >
        <AiTwotoneLeftCircle
          size={28}
          className={`absolute cursor-pointer -right-3 top-9 w-7 border-dark-purple border-2 rounded-full duration-500 ${!open ? 'rotate-180' : ''}`}
          onClick={() => setOpen(!open)}
        />
        <div className="flex gap-x-4 items-center">
          <h1 className={`text-white origin-left font-medium text-xl duration-500 ${!open && 'scale-0'}`}>
            ชั้นข้อมูล
          </h1>
        </div>
        <ul className="pt-3">
          {Menus.map((Menu, index) => (
            <React.Fragment key={index}>
              <li
                className={`flex rounded-md p-5 cursor-pointer hover:bg-teal-400 text-white text-sm items-center gap-x-4 ${Menu.gap ? 'mt-7' : 'mt-2'}`}
                onClick={Menu.onClick ? Menu.onClick : () => toggleSubMenu(index)} // Call onClick if defined
              >
                {Menu.icon}
                <span className={`flex-1 ${!open && 'hidden'} origin-left duration-200`}>{Menu.title}</span>
                {Menu.subMenus && (
                  <BsChevronDown className={`${!open && 'hidden'} ${subMenuOpen === index ? 'rotate-180' : ''}`} />
                )}
              </li>
              {Menu.subMenus && subMenuOpen === index && (
                <ul className="pl-6">
                  {Menu.subMenus.map((subMenuItem, idx) => (
                    <li key={idx} className={`${!open && 'hidden'} flex items-center text-sm text-white py-2`}>
                      {subMenuItem.type === 'checkbox' ? (
                        <Checkbox
                          checked={subMenuItem.checked}
                          onChange={subMenuItem.toggle}
                        >
                          <span className="text-white">{subMenuItem.title}</span>
                        </Checkbox>
                      ) : (
                        <span className="text-white">{subMenuItem.title}</span>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </React.Fragment>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
