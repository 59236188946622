import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createActivities, readActivities, updateActivities, deleteActivities } from './activitiesService';

const ActivityForm = ({ form, onChange, onSubmit, isEditing, onCancel }) => (
    <form onSubmit={onSubmit} className="mb-4">
        <input
            type="text"
            name="Location_ID"
            placeholder="Location ID"
            value={form.Location_ID}
            onChange={onChange}
            required
            disabled={isEditing} // Disable when editing
            className="border border-gray-300 rounded p-2 mb-2"
        />
        <input
            type="text"
            // type="date"
            name="Date"
            placeholder="Date"
            value={form.Date}
            onChange={onChange}
            required
            className="border border-gray-300 rounded p-2 mb-2"
        />
        <input
            type="text"
            name="Activity"
            placeholder="Activity"
            value={form.Activity}
            onChange={onChange}
            required
            className="border border-gray-300 rounded p-2 mb-2"
        />
        <button type="submit" className={`bg-${isEditing ? 'yellow' : 'green'}-500 text-white py-2 px-4 rounded`}>
            {isEditing ? 'Update' : 'Save'}
        </button>
        {isEditing && (
            <button type="button" onClick={onCancel} className="bg-gray-500 text-white py-2 px-4 rounded ml-2">
                Cancel
            </button>
        )}
    </form>
);

const ActivitiesTable = ({ activities, onEdit, onDelete }) => (
    <table className="min-w-full border-collapse border border-gray-300">
        <thead>
            <tr>
                <th className="border border-gray-300 p-2">Location ID</th>
                <th className="border border-gray-300 p-2">Date</th>
                <th className="border border-gray-300 p-2">Activity</th>
                <th className="border border-gray-300 p-2">Actions</th>
            </tr>
        </thead>
        <tbody>
            {activities.map(activity => (
                <tr key={activity.id}>
                    <td className="border border-gray-300 p-2">{activity.location_id}</td>
                    <td className="border border-gray-300 p-2">{activity.activity_date}</td>
                    <td className="border border-gray-300 p-2">{activity.activity}</td>
                    <td className="border border-gray-300 p-2">
                        <button onClick={() => onEdit(activity)} className="bg-yellow-500 text-white py-1 px-2 rounded mr-2">
                            Edit
                        </button>
                        <button onClick={() => onDelete(activity.id)} className="bg-red-500 text-white py-1 px-2 rounded">
                            Delete
                        </button>
                    </td>
                </tr>
            ))}
        </tbody>
    </table>
);

const ActivitiesEdit = () => {
    const [activities, setActivities] = useState([]);
    const [form, setForm] = useState({
        Location_ID: "",
        ID: "",
        Date: "",
        Activity: "",
    });
    const [editableId, setEditableId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        loadActivities();
    }, []);

    const loadActivities = async () => {
        setLoading(true);
        try {
            const result = await readActivities();
           // Ensure result is an array
            setActivities(Array.isArray(result) ? result : []);
        } catch (error) {
            setErrorMessage('Error loading activities. Please try again later.');
            console.error('Error loading activities:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        setLoading(true);
        try {
            const result = await deleteActivities(id);
            alert(result.message);
            if (result.status === 'ok') {
                loadActivities();
            }
        } catch (error) {
            setErrorMessage('Error deleting activity. Please try again.');
            console.error('Error deleting activity:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm((prev) => ({ ...prev, [name]: value }));
    };

    const resetForm = () => {
        setForm({ Location_ID: "", ID: "", Date: "", Activity: "" });
        setEditableId(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = {
            location_id: form.Location_ID,
            activity_date: form.Date,
            activity: form.Activity,
        };

        try {
            let result;
            if (editableId) {
                formData.id = editableId;
                result = await updateActivities(formData);
            } else {
                result = await createActivities(formData);
            }
            alert(result.message);
            if (result.status === 'ok') {
                loadActivities();
                resetForm();
            } else {
                throw new Error(result.message || 'Unknown error');
            }
        } catch (error) {
            setErrorMessage(`Error submitting activity: ${error.message}`);
            console.error('Error submitting activity:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = (activity) => {
        setEditableId(activity.id);
        setForm({
            Location_ID: activity.location_id,
            Date: activity.activity_date,
            Activity: activity.activity,
        });
    };

    const handleCancelEdit = () => {
        resetForm();
    };

    const handleBackToMap = () => {
        navigate('/userlist');
    };

    // Filter activities based on search query
    const filteredActivities = activities.filter(activity =>
        activity.location_id.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="flex flex-col items-center justify-center p-4">
            <div className="container mx-auto mt-4">
                <div className="bg-white shadow-md rounded-lg p-6">
                    <div className="flex justify-between mb-4">
                        <h2 className="text-2xl font-bold text-blue-600">Activities</h2>
                        <button 
                            onClick={resetForm}
                            className="bg-blue-500 text-white py-2 px-4 rounded">
                            {editableId ? "Cancel Edit" : "Create New Activity"}
                        </button>
                    </div>
                    
                    {/* Search Input */}
                    <input 
                        type="text" 
                        placeholder="Search by Location ID" 
                        value={searchQuery} 
                        onChange={(e) => setSearchQuery(e.target.value)} 
                        className="border p-2 mb-4 w-full"
                    />

                    {loading && <p>Loading...</p>}
                    {errorMessage && <p className="text-red-500">{errorMessage}</p>}

                    <ActivityForm 
                        form={form}
                        onChange={handleInputChange}
                        onSubmit={handleSubmit}
                        isEditing={editableId}
                        onCancel={handleCancelEdit}
                    />

                    <ActivitiesTable 
                        activities={filteredActivities}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                    />
                    
                    <button onClick={handleBackToMap} className="bg-blue-500 text-white py-2 px-4 rounded mt-4">
                        Back to Markers
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ActivitiesEdit;
