import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar'; // Adjust the import path as necessary
import MapComponent from './MapContainer'; // Adjust the import path as necessary
import ButtonEditdata from './ButtonEditdata';
import { useNavigate } from 'react-router-dom';

const Rendermap = () => {

  const [showMarker1, setShowMarker1] = useState(true);
  const navigate = useNavigate();
  const toggleMarker1 = () => setShowMarker1(!showMarker1);
 

  // Simulated authentication check (replace with your own logic)
  const isAuthenticated = true; // Change to your authentication logic

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/', { replace: true }); // Redirect to login if not authenticated
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className="flex flex-col h-screen">
      <div className="flex flex-grow">
        <Sidebar 
          showMarker1={showMarker1} 
          toggleMarker1={toggleMarker1} 
        />
        <div className="flex-grow">
          <MapComponent
            showMarker1={showMarker1} 
          />
          <ButtonEditdata/>

        </div>
 
        
      </div>
    </div>
  );
}

export default Rendermap;
